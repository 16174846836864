<template>
    <div>
        <Row v-for="(item, index) in formDynamic" :key="index">
            <Col span="6"> <Input type="text" v-model="item.value"></Input> </Col>
            <Col span="2">
            <Upload
                ref="uploadfile"
                :data="data"
                :headers="headers"
                :action="baseURL + url"
                :before-upload="beforeUpload"
                :show-upload-list="showUploadList"
                :max-size="maxSize"
                :on-success="
                    (response, file, fileList) => {
                        onSuccess(response, file, fileList, item);
                    }
                "
                :on-error="
                    (response, file, fileList) => {
                        onError(response, file, fileList, item);
                    }
                ">
                <Button :icon="icon" :size="btnUploadSize" :loading="btnUploadLoading">{{ btnUplodText }}</Button>
            </Upload>
            </Col>
            <Col span="4" v-if="item.index === 1"> <Button @click="handleAdd">继续添加附件</Button></Col>
            <Col span="6" v-if="item.index === 1">
            <div>单个文件小于5M,附件数量小于5个</div>
            </Col>
        </Row>
    </div>
</template>

<script>
import { storage } from '@/common/storage';
import config from 'config';

export default {
    name: 'svUpload',

    props: {
        icon: {
            type: String,
            default: ''
        },

        // 上传文件的url
        url: {
            type: String,
            default: () => ''
        },

        // 上传请求头部
        headers: {
            type: Object,
            default: () => {
                const token = storage.getToken();

                return { Authorization: `Bearer ${token}` };
            }
        },

        // 是否多个上传
        multiple: {
            type: Boolean,
            default: () => false
        },

        // 上传字段名称
        name: {
            type: String,
            default: () => 'file'
        },

        // 上传其他字段信息
        data: {
            type: Object,
            default: () => { }
        },

        // 上传loading
        btnUploadLoading: {
            type: Boolean,
            default: () => false
        },

        // 浏览文件按钮文本
        btnUplodText: {
            type: String,
            default: () => '浏览'
        },

        // 上传按钮大小
        btnUploadSize: {
            type: String,
            default: () => 'small'
        },

        // 是否显示上列表
        showUploadList: {
            type: Boolean,
            default: () => false
        },

        // 上传文件之前
        beforeUpload: {
            type: Function
        },

        // 附件列表
        formDynamic: {
            type: Array,
            default: () => []
        },

        // 文件大小限制
        maxSize: {
            type: Number,
            default: () => 5 * 1024
        }
    },

    data: function () {
        return {
            baseURL: config.baseURL,
            lists: [],
            arr: [],
            fileName: [],
            attachment: []
        };
    },

    methods: {
        /**
         * 清除文件
         */
        clearFiles() {
            this.$refs.uploadfile.clearFiles();
        },

        /**
         * 上传文件提交
         * @param {Object} file
         */
        post(file) {
            this.$refs.uploadfile.post(file);
        },

        /**
         * 成功处理
         * @param {Object} response
         * @param {Object} file
         * @param {Object} fileList
         */
        onSuccess(data, file, fileList, item) {
            if (data.success) {
                // 解决附件重复上传的问题
                this.arr.push(item.index);

                let indexLists = [...new Set(this.arr)];

                if (indexLists.includes(item.index)) {
                    this.lists[item.index - 1] = file;
                } else {
                    this.lists.push(file);
                }

                // 展示上传文件
                const responseList = this.lists.map(item => item.response);

                // 传给后端文件字段
                this.fileName = [];
                responseList.forEach(item => {
                    const { name } = item.result[0];
                    this.fileName.push(`${name}`);
                });

                this.formDynamic.forEach(item => {
                    this.fileName.forEach((item1, index) => {
                        if (item.index == index + 1) {
                            this.formDynamic[index].value = item1;
                        }
                    });
                });

                const resultList = responseList.map(item => item.result[0]);
                this.attachment = resultList.map(item => item.id);

                this.$emit('handleUpload', this.attachment);

                this.$Message.success('文件上传成功');
            } else {
                this.$Message.error(data.message);
            }
        },

        /**
         * 错误处理
         * @param {Object} error
         * @param {Object} file
         * @param {Object} fileList
         */
        onError(error, file, fileList, item) {
            switch (error.status) {
                case 401:
                    this.$Message.error(error.data.header.message);
                    break;
                default:
                    this.$Message.error('接口访问失败，请确认网络是否正常，如仍有问题，请与管理员联系。');
                    break;
            }

            this.$emit('onError', error, file, fileList, item);
        },
        handleAdd() {
            this.$emit('handleAdd');
        }
    }
};
</script>
